.sidebar {
    height : 71.5vh;
    background-color: #007ab6;
    color : white;
    font-size: 20px;
    text-align : center;
}

.top-bar {
    width : 100%;
    color : white;
    display: flex;
    flex-direction: space-between;
}

.reg {
    height : 10.5vh;
    width: 100%;
    font-size: 20px;
    background-color: #007ab6;
}

.top-bar-right {
    height : 10.5vh;
    width: 78.9%;
    font-size: 25px;
    text-align: left;
    margin-left: 0.1%;
    background-color: #007ab6;
}
.head-text {
    margin-top: 1%;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}
.link {
    text-decoration: none;
    color : white;
    line-height: 2.5;
    font-family: 'Poppins', sans-serif;
}
hr {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;
}

.addques {
    padding-top: 6%;
}

.res {
    padding-top: 5%;
}

.link:hover {
    color : rgb(211, 208, 208);
}

.CentralLoader{
    margin-top: 10em;
}

.link:active{
    color:rgb(211, 208, 208);
}


.Sidebar__Divider{
    background-color: #ffff;
}

.server_button{
    cursor: pointer;
    color: white;
    line-height: 2.5;
    font-family: 'Poppins', sans-serif;
}

.server_div{
    background-color: red;
    position: fixed;
    bottom: 0px;
    width: 21%;
} 
/* 
.logout_button{
    cursor: pointer;
    background-color : red;
    color: white;
    line-height: 2.5;
    font-family: 'Poppins', sans-serif;
}

.logout_div{
    margin-top: 11.9em;
} */