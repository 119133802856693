.scroll
{
    overflow-y: auto;
    height:70vh;
}
.boxg
{
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
    margin: 15px 25px 0px 25px;
    background-color: #f2f2f2;
    padding: 1em;
}
.leftside
{
    text-align: left;
    justify-self: flex-start;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
    border: 1px 1px;
    border-color: gray;
}

.leftside-name{
    word-spacing: 1.3px;
    line-height: 1.3em;
    font-weight:900;
}

.rightside
{
    text-align: left;
    font-family: 'Poppins', sans-serif;
    margin: 5px;
}
.resultgn
{
    background-color: #7ac943;
    padding: 3px 3px;
}
.resultbu
{
    background-color: #007AB6;
    padding: 3px 3px;
}

.resbtn{
    margin-top: 3em !important;
}
.searchbar{
    width: 100%;
    padding: 10px;
    margin-left: 10%;
}
.searchdiv{
    display : flex;
    flex-direction: row;
    padding: 30px 0px 20px;
    align-items: center;
}
.react-datepicker__input-container {
    width: 50% !important;
}
  
 
  