.regdiv1{
    height: 72vh;
    width: 78%;
    margin-left: 11%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.regdiv2{
    width: 70%;
    border: 2px solid grey;
    padding: 0px 10px 20px;
}
.regheading{
    margin: 0px;
    padding: 8px 0px;
}
hr{
    margin: 0px;
    border-color: grey;
}
.reginfohead{
    margin: 0px;
    text-align: left;
    padding: 20px 0px 5px 40%;
}
.regmkshead{
    margin: 0px;
    text-align: left;
    padding: 5px 0px 5px 14%;
}
.regsubhead{
    margin: 0px;
    padding: 5px 46% 5px 0px;
    text-align: right;
}
.regtotalhead{
    margin: 0px;
    padding: 5px 0px 12px;
}
.btn{
    background-color: #007AB6;
    padding: 6px 16px;
    color: white;
    cursor: pointer;
    border: none;
}

.parent-container {
    display : flex;
    justify-content: space-between;
}

.right-div {
    display: flex;
    flex-direction: column;
}
.left-div {
    width : 21%;
}
.top-bar-right {
    height : 9.5vh;
    width:99.9%;
}
.head-text {
    color: white
}

.gen-btn{
    margin-right: 2px;
}