
td:nth-child(even), th:nth-child(even) {
    background-color: #e6e7e8;
    width: 50%;
  }
  td:nth-child(odd), th:nth-child(odd) {
    background-color:#d2d3d4;
    width: 50%;
  }
  img{
      height:9vh;
      width:auto;
  
  }
#header 
{
    display: flex;
    justify-content: center;
    align-items: center;
    height:8vh;
    
}
#guh
{    font-family: 'Roboto', sans-serif;
    font-weight: bolder;
    font-size: 4vh;
padding-left: 3vh;}
#tex
{
    text-align: center;
}
.t2
{
    font-family: 'Noto Sans', sans-serif;
    font-size: 2vh;
}
.t3
{
    font-family: 'Roboto', sans-serif;
    font-size: 2vh;
    margin: 1em;
    text-decoration: underline;
}
.t4
{
    font-family: 'Roboto', sans-serif;
}
.t5
{
    font-family: 'Roboto', sans-serif;
    margin: 2vh;
    font-size: 4vh;
}
table
{
    width: 54%;
    margin-left: 23%;
    margin-bottom: 2%;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size:small;
}
#top
{
    background-color: #bcbec0;

}
#to
{
    background-color:#d2d3d4;
}

.ResultReport{
    max-width: 600px;
    margin: 2px auto;
}

.rtbtn{
    margin: 6px !important;
}

.space{
    display: inline;
    width: 1em;
}

.ButtonGroup{
    margin: 1em;
}