.adheaderpdiv1{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18vh;
}
.adheaderpdiv2{
    width: 94%;
    display: flex;
    justify-content: space-between;
}
.adheaderc1div{
    width: 500px;
}
.adheaderc2div{
    width: 250px;
    display: flex;
    align-items: center;
}