.Error500__image{
    height: 15em;
    width: 15em;
}

.Error500__error{
    font-family: 'Rajdhani', sans-serif;
    font-weight: 800;
    font-size: 2.3em;
}

.Error500_message{
    font-family: 'Rajdhani', sans-serif;
    font-size: 2em;
    font-weight: 500;
}

.Error500_Details{
    font-family: 'Rajdhani', sans-serif;
    font-weight: 500;
    font-size: medium;
}

.Error500_detailsdiv{
    margin-top: 2em;
}