.maindiv1{
    height: 71vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.maindiv2{
    width: 40%;
    padding: 30px 70px 40px;
    border: 2px solid grey;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2), 0 8px 22px 0 rgba(0, 0, 0, 0.19);
}
.mainhead1{
    margin: 0px;
}
.mainpara{
    text-align: left;
    padding: 30px 0px 10px;
    margin: 0px;
    font-size: 20px;
}
form{
    padding-bottom: 20px;
}
.formdiv{
    text-align: left;
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
}
.input{
    background-color: #f2f2f2;
    border: none;
    padding: 10px 30px;
    border-radius: none;
}
.submitbtn{
    color: white;
    background-color: blue;
    padding: 8px 40px;
    border: none;
    cursor: pointer;
}

.CentralLoader{
    margin-top: 12em;
}