.regdiv1 {
    width: 78%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.regdiv2 {
    width: 70%;
    border: 1px solid grey;
    text-align: center;
    padding: 0px 10px;
    padding-bottom: 15px;
}

.regdiv3 {
    border-bottom: 1px solid grey;
}

.regheading {
    margin: 0px;
    padding: 10px 0px;
}

.tex {
    padding: 5px 5px;}

.Register{
    display: inline;
}
.parent-container {
    display : flex;
    justify-content: space-between;
}

.right-div {
    display: flex;
    flex-direction: column;
    width : 79%;
}
.left-div {
    width : 21%;
}
.top-bar-right {
    height : 9.5vh;
    width:99.9%;
}
.head-text {
    color: white
}

.Success_Message{
    text-align: center;
}

.papa-container{
    height: 60vh;
    width: 78%;
    margin-left: 11%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.beta-container{
    width: 65%;
    height : 60%;
    border: 2px solid grey;
    padding: 0px 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}




.success{
    color: rgb(95, 95, 95);

    
}

.BackButton{
    width: 100px;
    display: block !important; 
    margin: 2px auto !important;
    color: #007AB6;
}

.Submitbutton{
    background-color: #007AB6 !important;
}

.Register__Paper{
    width: 30vw;
    margin: auto auto;
    padding: 2em;
}